<!--
 * @Description: 停车用户管理 活动管理 优惠活动 优惠活动详情 cuDiscountActivityDetails
 * @Author: zhoucheng
 * @Github: https://github.com/zhoucheng-tt
 * @Date: 2021-08-03 09:45:54
 * @LastEditors: zhoucheng
-->
<template>
  <div class="body">
    <el-row>
      <i class="el-icon-back"
         @click="returnClick"> 返回</i>
    </el-row>
    <!-- 内容部分 -->
    <el-row class="main">
      <el-row class="mainbody"
              v-model="viewForm">
        <el-row class="mainbodyIndex"
                :style="{background:viewForm.activityBackground}">
          <el-row class="header">
            <img class="headerPicture"
                 :src="$imgBaseUrl+viewForm.activityHeadImg"
                 alt="">
          </el-row>
          <el-row class="contentDiv">
            <el-col :span="18"
                    class="contentDiv-left">
              <el-row class="contentDiv-left-lineone">2元折扣券</el-row>
              <el-row class="contentDiv-left-linetwo">满十元可用</el-row>
              <el-row class="contentDiv-left-linethree">
                使用时间:{{viewForm.startTime}}~{{viewForm.endTime}}
              </el-row>
            </el-col>
            <el-col :span="6"
                    class="contentDiv-right">
              <span class="clickLin">点击领取</span>
            </el-col>
          </el-row>
          <el-row class="ruleLine">
            <img src="../../../../../assets/user/activityRule.png"
                 alt="">
            活动规则
          </el-row>
          <el-row class="bottom">
            <span class="bottom-content">
              {{viewForm.ruleDes}}
            </span>
          </el-row>
        </el-row>
      </el-row>
    </el-row>

    <!-- <div class="main">
      <el-form class='ActivityDetailsMain'
               :model="viewForm">
        <div class="back-color"
             :style="{background:viewForm.activityBackground}">
          <div class="topPictures"
               prop="activityHeadImg">
          </div>
          <div class="middle">
            <div class="middleText">
              <div class="middleTextOne">{{viewForm.activityContent}}</div>
              <div class="middleTextTwo">
                {{viewForm.startTime}}-{{viewForm.endTime}}
              </div>
            </div>
            <button class="middleButton">点击领取</button>
          </div>
          <div class="bottom">
            <div class="bottomTitle">
              活动规则
            </div>
            <div class="bottomContent"> {{viewForm.ruleDes}}</div>
          </div>
        </div>
      </el-form>
    </div> -->
  </div>
</template>

<script>
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    //这里存放数据
    return {
      viewForm: {},// 预览表单
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    // 去掉时间里面的“时分秒”
    this.$route.query.popForm.startTime = this.$route.query.popForm.startTime.split(" ")[0]
    this.$route.query.popForm.endTime = this.$route.query.popForm.endTime.split(" ")[0]
    //另一种写法，过滤“时分秒”
    // this.$route.query.popForm.startTime = this.$route.query.popForm.startTime.slice(0, 11)
    this.viewForm = this.$route.query.popForm
    console.log(this.viewForm, " this.viewForm this.viewForm this.viewForm")
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
  //方法集合
  methods: {
    // 点击返回按钮
    returnClick () {
      this.$router.go(-1)
    }
  }
}
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.body {
  width: 100%;
  height: 100%;
  overflow: hidden;
  .main {
    width: 100%;
    height: 98%;
    display: flex;
    justify-content: center;
    align-content: center;
    .mainbody {
      width: 23%;
      height: 100%;
      background-image: url('../../../../../assets/user/phone.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      z-index: -1;
      .mainbodyIndex {
        width: 89%;
        height: 92%;
        margin-left: 5.5%;
        margin-top: 11%;
        .header {
          width: 100%;
          height: 46%;
          .headerPicture {
            width: 100%;
            height: 100%;
          }
        }
        .contentDiv {
          width: 92%;
          height: 98px;
          margin-left: 4%;
          margin-top: 20px;
          border-radius: 15px;
          background: #ffffff;
          .contentDiv-left {
            width: 65%;
            height: 100%;
            padding-left: 3%;
            .contentDiv-left-lineone {
              height: 33.3%;
              line-height: 30px;
              font-weight: 600;
              color: #333333;
              font-size: 21px;
              padding-left: 7px;
              padding-top: 3.5px;
            }
            .contentDiv-left-linetwo {
              height: 33.3%;
              line-height: 35px;
              font-family: PingFang-SC-Medium;
              color: #333333;
              font-size: 15px;
              padding-left: 7px;
            }
            .contentDiv-left-linethree {
              height: 33.3%;
              line-height: 20px;
              font-family: PingFang-SC-Regular;
              font-size: 12px;
              color: #333333;
              padding-left: 7px;
            }
          }
          .contentDiv-right {
            float: right;
            width: 33%;
            height: 100%;
            background-image: url('../../../../../assets/carUserMangement/carRunManagement/activeBack.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            text-align: center;
            .clickLin {
              font-family: PingFang-SC-Regular;
              color: #ffffff;
              font-size: 18px;
              line-height: 98px;
            }
          }
        }
        .ruleLine {
          width: 60%;
          height: 24px;
          margin-left: 20%;
          margin-top: 20px;
          line-height: 24px;
          font-family: PingFang-SC-Medium;
          font-size: 17px;
          color: #ffffff;
          text-align: center;
          background-image: linear-gradient(
            90deg,
            rgba(111, 233, 213, 0) 0%,
            rgba(111, 233, 213, 0.5) 51%,
            rgba(111, 233, 213, 0) 100%
          );
        }
        .bottom {
          width: 92%;
          height: 160px;
          margin-left: 4%;
          margin-top: 20px;
          background: #ffffff;
          border-radius: 15px;
          font-size: 15px;
          color: #0b83b3;
          padding-left: 15px;
          padding-right: 15px;
          padding-top: 15px;
          .bottom-content {
          }
        }
      }
    }
  }
}
</style>